import React from 'react';

function App() {
  return (
    <div className="container mx-auto">

      <article className="prose-xl mb-5 mt-10">
        <h1>Domain Hack Tool</h1>
        <p className="w-full">
          A domain hack <code>dəʊˈmeɪn hæk</code> is an unconventional domain name that combines domain levels to spell out the full "name" or title of the domain.
        </p>
        <p>
          Domain hacks offer the ability to produce extremely short domain names.
        </p>
        <p>
          This makes them potentially valuable for uses such as URL shortening services. Popular examples are goo.gl, youtu.be, del.icio.us, t.co, and goatse.cx.
        </p>
      </article>

      <main>
        <div className="mb-3 pt-0">
          <input type="text" placeholder="Name to Domain Hack" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"/>
        </div>
      </main>

    </div>
  );
}

export default App;
